@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking {
  animation: blink 0.5s infinite; /* Blink twice within 1 second (0.5s per blink) */
}
