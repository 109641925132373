*{
  font-family: 'Noto Sans JP';
}


main {
  min-height: 80vh;
  
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
.product-title {
  height: 2.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  /* position: absolute;
  top: 0; */
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.carousel-caption h2 {
  color: #fff;
  
}
/* 
.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
} */
.carousel a {
  margin: 0 auto;
}
.carousel-indicators {
  margin: 0 !important; 
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

.carousel-image {
  width: 35%;
  /* Add any other styling for carousel items */
}

/* Media query for smaller devices (e.g., mobile phones) */
@media(max-width: 767px) {
  .carousel-image {
    width: 100%;
    /* Add any other styling for smaller devices */
  }
  .carousel-caption {
    bottom: 0;
    right: 0;
    left: 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
  }

  .carousel-caption h4 {
    font-size: 16px;
  }
}














